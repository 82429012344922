import * as React from 'react'
import Contact from '../components/contact'
import Layout from '../components/layout'
import Seo from '../components/seo'

const ContactPage = () => {
  return (
    <>
      <Seo
        pageTitle={'Contact Us'}
        pageDescription={
          'Get in touch with our expert team to give you some guidance on what we know best.'
        }
      />
      <Layout>
        <Contact></Contact>
      </Layout>
    </>
  )
}

export default ContactPage
